<template>
  <ErrorTemplate
    margin-after-image
    @onAction="onAction"
  />
</template>

<script>
import ErrorTemplate from './ErrorTemplate.vue'

export default {
  components: { ErrorTemplate },
  methods: {
    onAction() {
      window.location.replace('/login')
    },
  },
}
</script>
